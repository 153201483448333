@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: 217 100% 95%;
  --foreground: 217 5% 10%;
  --card: 217 50% 90%;
  --card-foreground: 217 5% 15%;
  --popover: 217 100% 95%;
  --popover-foreground: 217 100% 10%;
  --primary: 217 92% 54%;
  --primary-foreground: 0 0% 100%;
  --secondary: 217 30% 70%;
  --secondary-foreground: 0 0% 0%;
  --muted: 179 30% 25%;
  --muted-foreground: 217 5% 40%;
  --accent: 179 30% 80%;
  --accent-foreground: 217 5% 15%;
  --destructive: 0 100% 45%;
  --destructive-foreground: 217 5% 90%;
  --border: 217 30% 50%;
  --input: 217 30% 45%;
  --ring: 217 92% 54%;
  --radius: 0.5rem;
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #808080;
  --fc-border-color: #ddd;

  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(255, 220, 40, 0.15);
  --fc-now-indicator-color: red;
}
.dark {
  --background: 217 50% 10%;
  --foreground: 217 5% 90%;
  --card: 217 50% 10%;
  --card-foreground: 217 5% 90%;
  --popover: 217 50% 5%;
  --popover-foreground: 217 5% 90%;
  --primary: 217 92% 54%;
  --primary-foreground: 0 0% 100%;
  --secondary: 217 30% 20%;
  --secondary-foreground: 0 0% 100%;
  --muted: 179 30% 25%;
  --muted-foreground: 217 5% 60%;
  --accent: 179 30% 25%;
  --accent-foreground: 217 5% 90%;
  --destructive: 0 100% 45%;
  --destructive-foreground: 217 5% 90%;
  --border: 217 30% 45%;
  --input: 217 30% 45%;
  --ring: 217 92% 54%;
  --radius: 0.5rem;
  --chart-1: 220 70% 50%;
  --chart-5: 160 60% 45%;
  --chart-3: 30 80% 55%;
  --chart-4: 280 65% 60%;
  --chart-2: 340 75% 55%;
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #000000;
  --fc-neutral-bg-color: rgba(75, 78, 235, 0.3);
  --fc-neutral-text-color: rgba(75, 78, 235, 0.3);
  --fc-border-color: #1e2435;

  --fc-button-text-color: #000000;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;

  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #000;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

  --fc-more-link-bg-color: #000000;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: rgba(188, 232, 241, 0.3);
  --fc-now-indicator-color: red;
}
